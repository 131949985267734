import { post } from "./base";

const API_SERVICE = "/api/Service/V1/query";

export default {
  getInfo(fecha_ini, fecha_fin, sigla, provincia, distrito, quintil = "") {
    var tabla = `sivi_data.${sigla}`;
    const request = {
      _type: 1,
      _petitions: [
        {
          name: "_script",
          value:
            quintil == ""
              ? "web_seg_select_acumulado"
              : "web_seg_select_acumulado_quintil",
          type: "string"
        },
        {
          name: "@FECHA_INI",
          value: fecha_ini,
          type: "string"
        },
        {
          name: "@FECHA_FIN",
          value: fecha_fin,
          type: "string"
        },
        {
          name: "@TABLA",
          value: tabla,
          type: "int"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@DISTRITO",
          value: `%${distrito}%`,
          type: "string"
        },
        {
          name: "@QUINTIL",
          value: quintil,
          type: "string"
        }
      ]
    };

    console.log(request);
    return post(API_SERVICE, request);
  }
};
