<template>
  <a-form :model="formState" class="flex flex-col md:flex-row md:gap-5">
    <a-form-item label="FECHA INICIO" name="idperiodo_ini" class="my-1 inline-block md:w-52">
      <a-select v-model:value="formState.idperiodo_ini" @select="onSelectPeriodoIni">
        <a-select-option value="">TODOS</a-select-option>
        <a-select-option
          v-for="periodo in periodos"
          :value="periodo.idperiodo"
          :key="periodo.idperiodo"
        >
          {{ periodo.denominacion }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item label="FECHA FIN" name="idperiodo_fin" class="my-1 inline-block md:w-52">
      <a-select v-model:value="formState.idperiodo_fin" @select="onSelectPeriodoFin">
        <a-select-option value="">TODOS</a-select-option>
        <a-select-option
          v-for="periodo in periodos"
          :value="periodo.idperiodo"
          :key="periodo.idperiodo"
        >
          {{ periodo.denominacion }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item label="PROVINCIA" name="provincia" class="my-1 inline-block md:w-52">
      <a-select v-model:value="formState.provincia" @select="onSelectProvincia">
        <a-select-option key="ALL" value="">TODAS</a-select-option>
        <a-select-option v-for="p in provincias" :value="p.provincia" :key="p.provincia">
          {{ p.provincia }}
        </a-select-option>
      </a-select>
    </a-form-item>

    <a-form-item label="DISTRITO" name="distrito" class="my-1 inline-block md:w-52">
      <a-select v-model:value="formState.distrito" @select="onSelectDistrito">
        <a-select-option key="ALL" value="">TODOS</a-select-option>
        <a-select-option v-for="d in distritos" :value="d.distrito" :key="d.distrito">
          {{ d.distrito }}
        </a-select-option>
      </a-select>
    </a-form-item>
    
    <div id="quintil" style="display: none">
      <a-form-item label="QUINTIL 1 Y 2" name="quintil" class="my-1 inline-block md:w-40">
        <a-select v-model:value="formState.quintil" @select="onSelectQuintil">
          <a-select-option key="" value="">TODOS</a-select-option>
          <a-select-option value="1">SI</a-select-option>
          <a-select-option value="0">NO</a-select-option>
        </a-select>
      </a-form-item>
    </div>
  </a-form>

</template>

<script>
import { ref, reactive, onMounted, watch } from "vue";
import IndicadorApi from "@/api/indicador";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import GraficasApi from "@/api/graficas";

export default {
  props: {
    sigla: {
      type: String
    }
  },
  emits: ["changedistrito", "changePeriodo", "changeprovincia"],
  setup(props, { emit }) {
    const store = useStore();
    const periodos = ref([]);
    const provincias = ref([]);
    const distritos = ref([]);
    const route = useRoute();

    const formState = reactive({
      idperiodo_ini: store.getters["graficas/periodo_ini"],
      idperiodo_fin: store.getters["graficas/periodo_fin"],
      provincia: store.getters["graficas/provincia"],
      distrito: store.getters["graficas/distrito"],
      quintil: ""
    });

    const fetchPeriodos = (periodo, idindicador) => {
      IndicadorApi.getperiodoByByIDIndicador(idindicador)
        .then((response) => {
          periodos.value = response.data;
        })
        .catch((err) => console.log(err));
    };

    const fetchProvincias = () => {
      IndicadorApi.getProvincias()
        .then((response) => {
          // console.log(response);
          provincias.value = response.data;
        })
        .catch((err) => console.log(err));
    };

    const fetchDistritos = (provincia = "") => {
      IndicadorApi.getDistritos(provincia)
        .then((response) => {
          // console.log(response);
          distritos.value = response.data;
        })
        .catch((err) => console.log(err));
    };

    onMounted(async () => {
      const periodo = store.getters["mapa/periodo"];
      const idindicador = route.params?.indicador;

      const response = await GraficasApi.getAllSiglaIndicador(idindicador);
      store.dispatch("mapa/setSigla", response.data[0].sigla);

      if (periodo > 0) {
        const response = await GraficasApi.getAllPeriodoEvaluacion(periodo);

        formState.idperiodo_ini = response.data[0].idperiodoinicio;
        formState.idperiodo_fin = response.data[0].idperiodofinal;
      } else {
        const anio = new Date().getFullYear();
        const month = new Date().getMonth();
        const month_ini = month - 2 < 10 ? "" + 0 + (month - 2) : month - 2;
        const month_fin = month - 1 < 10 ? "" + 0 + (month - 1) : month - 1;

        formState.idperiodo_ini = "" + anio + month_ini;
        formState.idperiodo_fin = "" + anio + month_fin;
      }
      
      if (idindicador == 14 || idindicador == 15) {
        document.getElementById("quintil").style.display = "block";
      }

      store.dispatch("graficas/setPeriodoIni", formState.idperiodo_ini);
      store.dispatch("graficas/setPeriodoFin", formState.idperiodo_fin);

      emit("changedistrito");
      emit("changePeriodo");

      fetchPeriodos(periodo, idindicador);
      fetchProvincias();
      fetchDistritos();
    });

    watch(formState, () => {
      console.log(formState);
    });

    const onSelectProvincia = (provincia) => {
      store.dispatch("graficas/setProvincia", provincia);
      store.dispatch("graficas/setDistrito", "");

      formState.distrito = "";
      emit("changedistrito");
      fetchDistritos(provincia);
    };

    const onSelectPeriodoIni = (periodo) => {
      store.dispatch("graficas/setPeriodoIni", periodo);
      emit("changePeriodo");
    };

    const onSelectPeriodoFin = (periodo) => {
      store.dispatch("graficas/setPeriodoFin", periodo);
      emit("changePeriodo");
    };

    const onSelectDistrito = (ubigeo) => {
      store.dispatch("graficas/setDistrito", ubigeo);
      emit("changedistrito");
    };

    const onSelectQuintil = (quintil) => {
      store.dispatch("graficas/setQuintil", quintil);
      emit("changePeriodo");
    };

    const cleanFilters = () => {
      formState.idperiodo = "";
      formState.provincia = "";
      formState.distrito = "";

      store.dispatch("mapa/setProvincia", "");
      store.dispatch("mapa/setPeriodoIni", "");
      store.dispatch("mapa/setPeriodoFin", "");
      store.dispatch("mapa/setUbigeo", "");

      emit("changedistrito");
      emit("changePeriodo");
    };

    return {
      formState,
      periodos,
      provincias,
      distritos,
      onSelectPeriodoIni,
      onSelectPeriodoFin,
      onSelectProvincia,
      onSelectDistrito,
      cleanFilters,
      onSelectQuintil
    };
  }
};
</script>

<style scoped></style>
