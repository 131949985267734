import { post } from "./base";

const API_SERVICE_QUERY = "/api/Service/V1/query";

export default {
  getAllByProvincia: (sigla, provincia, periodo_ini, periodo_fin, quintil = "") => {
    var tabla = `sivi_data.` + sigla;

    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value:
            quintil == ""
              ? "web_data_select_grafica_provincia"
              : "web_data_select_grafica_provincia_quintil",
          type: "string"
        },
        {
          name: "@TABLE",
          value: tabla,
          type: "int"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@PERIDO_INI",
          value: periodo_ini,
          type: "string"
        },
        {
          name: "@PERIOIDO_FIN",
          value: periodo_fin,
          type: "string"
        },
        {
          name: "@QUINTIL",
          value: quintil,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  getAllByDistrito: (sigla, provincia, periodo_ini, periodo_fin, distrito, quintil = "") => {
    var tabla = `sivi_data.` + sigla;
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value:
            quintil == ""
              ? "web_data_select_grafica_distrito"
              : "web_data_select_grafica_distrito_quintil",
          type: "string"
        },
        {
          name: "@TABLE",
          value: tabla,
          type: "int"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@PERIDO_INI",
          value: periodo_ini,
          type: "string"
        },
        {
          name: "@PERIOIDO_FIN",
          value: periodo_fin,
          type: "string"
        },
        {
          name: "@DISTRITO",
          value: `%${distrito}%`,
          type: "string"
        },
        {
          name: "@QUINTIL",
          value: quintil,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  getAllByPeriodo: (sigla, provincia, distrito, periodo_ini, periodo_fin, quintil = "") => {
    var tabla = `sivi_data.` + sigla;

    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value:
            quintil == ""
              ? "web_data_select_grafica_idperido"
              : "web_data_select_grafica_idperido_quintil",
          type: "string"
        },
        {
          name: "@TABLE",
          value: tabla,
          type: "int"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@PERIDO_INI",
          value: periodo_ini,
          type: "string"
        },
        {
          name: "@PERIOIDO_FIN",
          value: periodo_fin,
          type: "string"
        },
        {
          name: "@DISTRITO",
          value: `%${distrito}%`,
          type: "string"
        },
        {
          name: "@QUINTIL",
          value: quintil,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  getAllByGeneral: (sigla, provincia, distrito, periodo_ini, periodo_fin, quintil = "") => {
    var tabla = `sivi_data.` + sigla;

    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value:
            quintil == ""
              ? "web_data_select_grafica_general"
              : "web_data_select_grafica_general_quintil",
          type: "string"
        },
        {
          name: "@TABLE",
          value: tabla,
          type: "int"
        },
        {
          name: "@PROVINCIA",
          value: `%${provincia}%`,
          type: "string"
        },
        {
          name: "@PERIDO_INI",
          value: periodo_ini,
          type: "string"
        },
        {
          name: "@PERIOIDO_FIN",
          value: periodo_fin,
          type: "string"
        },
        {
          name: "@DISTRITO",
          value: `%${distrito}%`,
          type: "string"
        },
        {
          name: "@QUINTIL",
          value: quintil,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  getAllMicrored: (sigla) => {
    var tabla = `sivi_data.` + sigla;

    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_grafica_microred",
          type: "string"
        },
        {
          name: "@TABLE",
          value: tabla,
          type: "int"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  getAllEstablecimiento: (sigla, microred) => {
    var tabla = `sivi_data.` + sigla;

    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_grafica_establecimiento",
          type: "string"
        },
        {
          name: "@TABLE",
          value: tabla,
          type: "int"
        },
        {
          name: "@MICRORED",
          value: `%${microred}%`,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  getAllByMicrored: (sigla, microred, periodo_ini, periodo_fin) => {
    var tabla = `sivi_data.` + sigla;

    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_grafica_microred_data",
          type: "string"
        },
        {
          name: "@TABLE",
          value: tabla,
          type: "int"
        },
        {
          name: "@MICRORED",
          value: `%${microred}%`,
          type: "string"
        },
        {
          name: "@PERIDO_INI",
          value: periodo_ini,
          type: "string"
        },
        {
          name: "@PERIOIDO_FIN",
          value: periodo_fin,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  getAllByEstablecimiento: (sigla, microred, periodo_ini, periodo_fin, establecimiento) => {
    var tabla = `sivi_data.` + sigla;
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_grafica_establecimiento_data",
          type: "string"
        },
        {
          name: "@TABLE",
          value: tabla,
          type: "int"
        },
        {
          name: "@MICRORED",
          value: `%${microred}%`,
          type: "string"
        },
        {
          name: "@PERIDO_INI",
          value: periodo_ini,
          type: "string"
        },
        {
          name: "@PERIOIDO_FIN",
          value: periodo_fin,
          type: "string"
        },
        {
          name: "@ESTABLECIMIENTO",
          value: `%${establecimiento}%`,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  getAllByPeriodoEstablecimiento: (sigla, microred, establecimiento, periodo_ini, periodo_fin) => {
    var tabla = `sivi_data.` + sigla;

    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_grafica_idperido_establecimiento",
          type: "string"
        },
        {
          name: "@TABLE",
          value: tabla,
          type: "int"
        },
        {
          name: "@MICRORED",
          value: `%${microred}%`,
          type: "string"
        },
        {
          name: "@PERIDO_INI",
          value: periodo_ini,
          type: "string"
        },
        {
          name: "@PERIOIDO_FIN",
          value: periodo_fin,
          type: "string"
        },
        {
          name: "@ESTABLECIMIENTO",
          value: `%${establecimiento}%`,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  getAllByGeneralEstablecimiento: (sigla, microred, establecimiento, periodo_ini, periodo_fin) => {
    var tabla = `sivi_data.` + sigla;

    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_grafica_general_establecimineto",
          type: "string"
        },
        {
          name: "@TABLE",
          value: tabla,
          type: "int"
        },
        {
          name: "@MICRORED",
          value: `%${microred}%`,
          type: "string"
        },
        {
          name: "@PERIDO_INI",
          value: periodo_ini,
          type: "string"
        },
        {
          name: "@PERIOIDO_FIN",
          value: periodo_fin,
          type: "string"
        },
        {
          name: "@ESTABLECIMIENTO",
          value: `%${establecimiento}%`,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  getAllPeriodoEvaluacion: (idperiodoEvaluacion) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_periodoeval",
          type: "string"
        },
        {
          name: "@IDPERIODOEVALUACION",
          value: `${idperiodoEvaluacion}`,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  getAllSiglaIndicador: (indicador) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_seg_select_reporte_indicador",
          type: "string"
        },
        {
          name: "@INDICADOR",
          value: `${indicador}`,
          type: "int"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  }
};
