<template>
  <div class="flex gap-2 mb-4 md:gap-3 lg:gap-4">
    <div class="w-full border bg-white font-semibold">
      <p class="text-black">
        <span class="bg-green-600 py-2 px-6 text-white">Indicador: {{ abreviatura }} </span>
      </p>
      <p class="px-4 py-2 lg:text-lg lg:p-4">
        {{ denominacion }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    abreviatura: {
      type: String,
      default: ""
    },
    denominacion: {
      type: String,
      default: ""
    }
  }
};
</script>

<style></style>
