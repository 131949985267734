<template>
  <a-page-header @back="() => $router.go(-1)" title="GRÁFICAS DEL INDICADOR" class="pt-0 px-0">
    <template #extra>
      <a-breadcrumb>
        <a-breadcrumb-item><router-link to="/menu">Proyecto</router-link></a-breadcrumb-item>
        <a-breadcrumb-item><router-link to="/indicador">Indicador</router-link></a-breadcrumb-item>
        <a-breadcrumb-item
          ><router-link :to="'/indicador/' + idindicador">Resumen</router-link></a-breadcrumb-item
        >
        <a-breadcrumb-item>Gráficas</a-breadcrumb-item>
      </a-breadcrumb>
    </template>
  </a-page-header>

  <TituloIndicador
    :abreviatura="indicadorData.abreviatura"
    :denominacion="indicadorData.denominacion"
  />

  <div class="flex flex-wrap">
    <fieldset class="border border-solid border-gray-300 p-3 px-8 w-full lg:w-auto">
      <legend class="text-sm pl-4">Filtra el indicador</legend>
      <IndicadorGraficasFiltros @changedistrito="changedistrito" @changePeriodo="changePeriodo" />
    </fieldset>

    <fieldset class="flex gap-4 border border-solid border-gray-300 p-3 px-8">
      <legend class="text-sm pl-4">Acciones</legend>

      <button @click="showModalPrintModal" class="text-center">
        <div class="text-3xl" :class="'text-red-400'">
          <FilePdfOutlined />
        </div>
        Visualizar
      </button>

      <button @click="handleReporteIndicador" class="text-center">
        <div class="text-3xl" :class="'text-green-500'">
          <FileTextOutlined />
        </div>
        <p>Ver Ficha</p>
      </button>
    </fieldset>
  </div>

  <div class="w-full flex flex-wrap lg:flex-nowrap gap-4 mt-5">
    <div class="flex flex-col w-full lg:w-1/2 gap-4">
      <a-card size="small" class="w-full relative">
        <template #title>
          <h1 class="text-center font-semibold text-lg">AVANCE</h1>
        </template>
        <v-chart :option="grafica.gauge.options" style="width: 100%; height: 300px" />

        <div class="md:flex md:justify-center md:gap-2 absolute bottom-8">
          <div
            v-for="escala in escalaColores"
            :key="escala.texto"
            class="flex gap-2 items-center text-xxs"
          >
            <div
              class="w-3 h-3 rounded-md border border-gray-500"
              :style="{ backgroundColor: escala.color }"
            ></div>
            {{ escala.texto }}
          </div>
        </div>
      </a-card>

      <a-card size="small">
        <template #title>
          <h1 class="text-center font-semibold text-lg">AVANCE POR MESES</h1>
        </template>
        <v-chart :option="grafica.line.options" style="width: 100%; height: 300px" />
      </a-card>
    </div>
    <div class="flex flex-col w-full lg:w-1/2 gap-4">
      <a-card size="small">
        <template #title>
          <h1 class="text-center font-semibold text-lg">DETALLE POR PROVINCIA</h1>
        </template>
        <v-chart :option="graphics.provincia" style="width: 100%; height: 300px" />
      </a-card>

      <a-card size="small">
        <template #title>
          <h1 class="text-center font-semibold text-lg">DETALLE POR MESES</h1>
        </template>
        <v-chart :option="grafica.barLabel.options" style="width: 100%; height: 300px" />
      </a-card>
    </div>
  </div>

  <div class="flex flex-col w-full mt-4">
    <a-card size="small">
      <template #title>
        <h1 class="text-center font-semibold text-lg">AVANCE ACUMULADO POR MESES</h1>
      </template>
      <v-chart :option="grafica.line.optionsAcumulado" style="width: 100%; height: 300px" />
    </a-card>
  </div>

  <div class="flex flex-col w-full mt-4">
    <a-card size="small" class="overflow-x-auto">
      <template #title>
        <h1 class="text-center font-semibold text-lg">AVANCE POR DISTRITO</h1>
      </template>
      <v-chart :option="grafica.barRainfall.options" :style="{ height: height + 'px' }" />
    </a-card>
  </div>

<!----
  <a-alert class="fixed bottom-1 right-1 z-10" message="Leyenda" closable>
    <template #description>
      <div v-for="escala in escalaColores" :key="escala.texto" class="flex gap-2 items-center">
        <div
          class="w-3 h-3 rounded-md border border-gray-500"
          :style="{ backgroundColor: escala.color }"
        ></div>
        {{ escala.texto }}
      </div>
    </template>
  </a-alert>
-->


  <a-modal
    class="max-w-sm md:max-w-5xl text-center"
    width="100%"
    :visible="isVisiblePrintModal"
    :footer="null"
    @close="closeModalPrintModal"
    @cancel="closeModalPrintModal"
  >
    <a-button
      shape="round"
      v-print="objPrintIndicadorGrafica"
      size="large"
      class="text-center mx-auto mb-8 bg-red-500 text-white"
    >
      <template #icon>
        <PrinterOutlined />
        Imprimir
      </template>
    </a-button>

    <div id="printIndicadorGrafica">
      <PrintHeader />

      <div class="mt-5">
        <TituloIndicador
          :abreviatura="indicadorData.abreviatura"
          :denominacion="indicadorData.denominacion"
        />
      </div>

      <div class="flex gap-5 w-full my-5">
        <a-card size="small" class="w-1/2">
          <template #title>
            <h1 class="text-center font-semibold text-lg">PORCENTAJE DE AVANCE</h1>
          </template>
          <v-chart :option="grafica.gauge.options" style="width: 100%; height: 300px" />
        </a-card>

        <!-- copiar a publico ... -->
        <a-card size="small" class="w-1/2">
          <template #title>
            <h1 class="text-center font-semibold text-lg">AVANCE POR MESES</h1>
          </template>
          <v-chart :option="grafica.line.options" style="width: 100%; height: 300px" />
        </a-card>
      </div>

      <div class="flex gap-3 w-full mb-5">
        <a-card size="small" class="w-1/2">
          <template #title>
            <h1 class="text-center font-semibold text-lg">DETALLE POR PROVINCIA</h1>
          </template>
          <v-chart :option="graphics.provincia" style="width: 100%; height: 300px" />
        </a-card>

        <a-card size="small" class="w-1/2">
          <template #title>
            <h1 class="text-center font-semibold text-lg">DETALLE POR MESES</h1>
          </template>
          <v-chart :option="grafica.barLabel.options" style="width: 100%; height: 300px" />
        </a-card>
      </div>

      <a-card size="small" class="w-full">
        <template #title>
          <h1 class="text-center font-semibold text-lg">DETALLE POR DISTRITO</h1>
        </template>
        <v-chart :option="grafica.barRainfall.options" :style="{ height: height + 'px' }" />
      </a-card>
    </div>
  </a-modal>
</template>

<script>
import { ref, toRaw, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import GraficasApi from "@/api/graficas";
import MasivoApi from "@/api/masivo";
import IndicadorGraficasFiltros from "./IndicadorGraficasFiltros.vue";
import PrintHeader from "@/components/PrintHeader.vue";
import TituloIndicador from "@/components/TituloIndicador.vue";
import { FilePdfOutlined, FileTextOutlined, PrinterOutlined } from "@ant-design/icons-vue";
import {
  calculaPorcentaje,
  semaforoByPorcentajeIndicador,
  generaLeyendaSemaforo,
  formateaIdsPeriodoHuman,
generaLeyendaSemaforoInverso
} from "@/utils";
import ApiIndicador from "@/api/indicador";
import MapaApi from "@/api/mapa";

import {
  optionsGauge,
  optionsPie,
  optionsBar,
  optionsLine,
  optionsLineAcumulado,
  optionsBarLabel,
  provinciaGraphic
} from "./utilsIndicadorGrafica";

export default {
  components: {
    IndicadorGraficasFiltros,
    PrintHeader,
    TituloIndicador,
    // indicadores
    FilePdfOutlined,
    FileTextOutlined,
    PrinterOutlined
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const idindicador = ref("");
    const height = ref("700");
    const isVisiblePrintModal = ref(false);
    const porcentajeColor = ref("");
    const escalaColores = ref([]);
    const porcentaje = ref("");
    const isUmbralInverso = ref(idindicador.value === 14);

    const graphics = reactive({
      provincia: null
    });

    const indicadorData = reactive({
      denominacion: "",
      abreviatura: ""
    });

    const coloresSemaforo = reactive({
      RANGO_SINDATO: "RANGO_SINDATO",
      RANGO_BAJO: "RANGO_BAJO",
      RANGO_MEDIO: "RANGO_MEDIO",
      RANGO_ALTO: "RANGO_ALTO"
    });

    const coloresSemaforoMapa = reactive({
      DENOMINADOR: "5",
      RANGO_SINDATO: "5",
      RANGO_BAJO: "10",
      RANGO_MEDIO: "20",
      RANGO_ALTO: "30"
    });

    const objPrintIndicadorGrafica = reactive({
      id: "printIndicadorGrafica",
      popTitle: "SIVINUR | GRÁFICAS DE INDICADOR"
    });

    const grafica = reactive({
      gauge: {
        options: null
      },
      pie: {
        options: null
      },
      barRainfall: {
        options: null,
        title: ""
      },
      line: {
        options: null,
        title: "",
        optionsAcumulado: null,
        titleAcumulado: ""
      },
      barLabel: {
        options: null
      }
    });

    idindicador.value = route.params?.indicador;

    const getInfoIndicador = () => {
      ApiIndicador.getInfo(idindicador.value)
        .then((res) => {
          indicadorData.denominacion = res.data[0].denominacion;
          indicadorData.abreviatura = res.data[0].abreviatura;
        })
        .catch((error) => console.log(error));
    };

    const getAllByGeneral = () => {
      const sigla = store.getters["mapa/sigla"];
      const provincia = store.getters["graficas/provincia"];
      const distrito = store.getters["graficas/distrito"];
      const periodo_ini = store.getters["graficas/periodo_ini"];
      const periodo_fin = store.getters["graficas/periodo_fin"];
      const quintil = store.getters["graficas/quintil"];

      GraficasApi.getAllByGeneral(sigla, provincia, distrito, periodo_ini, periodo_fin, quintil)
        .then((response) => {
          porcentaje.value = calculaPorcentaje(
            response.data[0].total_numerador,
            response.data[0].total_denominador
          );

          porcentajeColor.value = semaforoByPorcentajeIndicador(
            coloresSemaforoMapa.RANGO_SINDATO,
            coloresSemaforoMapa.RANGO_BAJO,
            coloresSemaforoMapa.RANGO_MEDIO,
            porcentaje.value
          );

          grafica.gauge.options = optionsGauge(porcentaje.value, porcentajeColor);
        })
        .catch((err) => console.log(err));
    };

    const getAllAcumulado = () => {
      const sigla = store.getters["mapa/sigla"];
      const periodo_ini = store.getters["graficas/periodo_ini"];
      const periodo_fin = store.getters["graficas/periodo_fin"];
      const provincia = store.getters["graficas/provincia"];
      const distrito = store.getters["graficas/distrito"];
      const quintil = store.getters["graficas/quintil"];

      MasivoApi.getInfo(periodo_ini, periodo_fin, sigla, provincia, distrito, quintil)
        .then((response) => {
          var suma = 0;
          var months = [];
          var porcentaje = [];

          response.data.forEach((element) => {
            suma += parseInt(element.total);
            porcentaje.push(suma);
            months.push(formateaIdsPeriodoHuman(element.idperiodo));
          });

          grafica.line.optionsAcumulado = optionsLineAcumulado(months, porcentaje);
        })
        .catch((err) => console.log(err));
    };

    const getAllByProvincia = () => {
      const sigla = store.getters["mapa/sigla"];
      const provincia = store.getters["graficas/provincia"];
      const periodo_ini = store.getters["graficas/periodo_ini"];
      const periodo_fin = store.getters["graficas/periodo_fin"];
      const quintil = store.getters["graficas/quintil"];

      GraficasApi.getAllByProvincia(sigla, provincia, periodo_ini, periodo_fin, quintil)
        .then((response) => {
          const barData = [];

          response.data.forEach((element) => {
            barData.push({
              value: calculaPorcentaje(
                element.total_numerador_provincia,
                element.total_denominador_provincia
              ),
              name: element.provincia
            });
          });

          grafica.pie.options = optionsPie(barData);

          graphics.provincia = provinciaGraphic(barData);
        })
        .catch((err) => console.log(err));
    };

    const getAllByDistritos = () => {
      const sigla = store.getters["mapa/sigla"];
      const provincia = store.getters["graficas/provincia"];
      const distrito = store.getters["graficas/distrito"];
      const periodo_ini = store.getters["graficas/periodo_ini"];
      const periodo_fin = store.getters["graficas/periodo_fin"];
      const quintil = store.getters["graficas/quintil"];

      GraficasApi.getAllByDistrito(sigla, provincia, periodo_ini, periodo_fin, distrito, quintil)
        .then((response) => {
          const distrito = [];
          const total_denominador_distrito = [];
          const total_numerador_distrito = [];
          const total_porcentaje_distrito = [];

          response.data.forEach((element) => {
            distrito.push(element.distrito);
            total_numerador_distrito.push(element.total_numerador_distrito);
            total_denominador_distrito.push(element.total_denominador_distrito);
            total_porcentaje_distrito.push(
              calculaPorcentaje(
                element.total_numerador_distrito,
                element.total_denominador_distrito
              )
            );
          });

          grafica.barRainfall.options = optionsBar(
            distrito,
            total_numerador_distrito,
            total_denominador_distrito,
            total_porcentaje_distrito
          );
        })
        .catch((err) => console.log(err));
    };

    const getAllByPeriodo = () => {
      const sigla = store.getters["mapa/sigla"];
      const provincia = store.getters["graficas/provincia"];
      const distrito = store.getters["graficas/distrito"];
      const periodo_ini = store.getters["graficas/periodo_ini"];
      const periodo_fin = store.getters["graficas/periodo_fin"];
      const quintil = store.getters["graficas/quintil"];

      GraficasApi.getAllByPeriodo(sigla, provincia, distrito, periodo_ini, periodo_fin, quintil)
        .then((response) => {
          const months = [];
          const porcentaje = [];
          const total_numerador_periodo = [];
          const total_denominador_periodo = [];

          response.data.forEach((element) => {
            months.push(formateaIdsPeriodoHuman(element.idperiodo));
            total_numerador_periodo.push(element.total_numerador_periodo);
            total_denominador_periodo.push(element.total_denominador_periodo);
            porcentaje.push(
              calculaPorcentaje(element.total_numerador_periodo, element.total_denominador_periodo)
            );
          });

          grafica.line.options = optionsLine(months, porcentaje);

          grafica.barLabel.options = optionsBarLabel(
            months,
            total_numerador_periodo,
            total_denominador_periodo
          );
        })
        .catch((err) => console.log(err));
    };

    const handleReporteIndicador = () => {
      const sigla = store.getters["mapa/sigla"];
      window.open(`${process.env.VUE_APP_BASE_API}/FICHAS/${sigla}.pdf`, "_blank");
    };

    const getEscalaColoresMapa = async () => {
      const res = await MapaApi.getSemaforoMapaPublicv2(idindicador.value);

      coloresSemaforo.RANGO_SINDATO = res.data.find((p) => p.clave === "RANGO_SINDATO").descripcion;
      coloresSemaforo.RANGO_BAJO = res.data.find((p) => p.clave === "RANGO_BAJO").descripcion;
      coloresSemaforo.RANGO_MEDIO = res.data.find((p) => p.clave === "RANGO_MEDIO").descripcion;
      coloresSemaforo.RANGO_ALTO = res.data.find((p) => p.clave === "RANGO_ALTO").descripcion;

      coloresSemaforoMapa.RANGO_SINDATO = res.data.find((p) => p.clave === "RANGO_SINDATO").valor;
      coloresSemaforoMapa.RANGO_BAJO = res.data.find((p) => p.clave === "RANGO_BAJO").valor;
      coloresSemaforoMapa.RANGO_MEDIO = res.data.find((p) => p.clave === "RANGO_MEDIO").valor;
      coloresSemaforoMapa.RANGO_ALTO = res.data.find((p) => p.clave === "RANGO_ALTO").valor;

      if(isUmbralInverso.value){
        escalaColores.value = generaLeyendaSemaforoInverso(toRaw(coloresSemaforo));
      } else {
        escalaColores.value = generaLeyendaSemaforo(toRaw(coloresSemaforo));
      }

    };

    onMounted(() => {
      getInfoIndicador();
      getEscalaColoresMapa();
    });

    const changedistrito = () => {
      getAllByGeneral();
      getAllByProvincia();
      getAllByDistritos();
      getAllByPeriodo();
      getAllAcumulado();
    };

    const changePeriodo = () => {
      getAllByGeneral();
      getAllByProvincia();
      getAllByDistritos();
      getAllByPeriodo();
      getAllAcumulado();
    };

    const showModalPrintModal = () => {
      isVisiblePrintModal.value = true;
    };
    const closeModalPrintModal = () => {
      isVisiblePrintModal.value = false;
    };

    const reloadGraphics = () => {
      store.dispatch("graficas/cleanFilters");
      getInfoIndicador();
    };

    return {
      changedistrito,
      changePeriodo,
      grafica,
      height,
      handleReporteIndicador,
      graphics,
      indicadorData,
      idindicador,
      objPrintIndicadorGrafica,
      isVisiblePrintModal,
      showModalPrintModal,
      closeModalPrintModal,
      reloadGraphics,
      escalaColores
    };
  }
};
</script>

<style></style>
